<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'Systemtemplate:Save'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="deleteMultiple" v-right-code="'Systemtemplate:Delete'">删除</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="enable" v-right-code="'Systemtemplate:Enable'">启用</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="disable" v-right-code="'Systemtemplate:Enable'">停用</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="sysLogListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="(!config.isDetailDisplay)"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='MsgTplCode'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作" :min-width="130" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="showReset(scope.row)" size="small" type="text">编辑</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on(this.config.moduleEvent.initial, () => this.reloadPageList());
            this.Event.$on(this.config.moduleEvent.add, () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/systemtemplate/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/systemtemplate/get", "get", { id: row.Id , date: this.value }, (data) => {
                    this.Event.$emit("clearEditSysLogForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            add() {
                this.$ajax.send("omsapi/systemtemplate/get", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            deleteMultiple() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return false;
                }
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
                this.delete(ids);
            },
            delete(ids){
                var me = this;
                this.Utils.confirm({
                    title: "提示：",
                    content: "删除后不可恢复，请确认?"
                }, () => {
                    this.$ajax.send("omsapi/systemtemplate/delete", "post", ids, (data) => {
                        me.onPageChange(me.queryParam);
                        me.Utils.messageBox("删除成功.", "success");
                    });
                }, () => {
                    me.Utils.messageBox("已取消.", "info");
                });
            },
            enable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return;
                }
                debugger
                var statusList = this.Utils.selectionsToArr(this.multipleSelection, "MsgTplStatus");
                if(statusList.indexOf(100)>-1){
                    this.Utils.messageBox(`存在启用数据.`, "error");
                    return;
                }
                this.able(100);
            },
            disable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择数据.`, "error");
                    return;
                }
                debugger
                var statusList = this.Utils.selectionsToArr(this.multipleSelection, "MsgTplStatus");
                if(statusList.indexOf(200)>-1){
                    this.Utils.messageBox(`存在停用数据.`, "error");
                    return;
                }
                this.able(200);
            },
            able(bizType) {
                var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");
                this.$ajax.send("omsapi/systemtemplate/enable", "post", ids, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox((bizType==100?"启用":"停用")+"成功.", "success");
                });
            },
        }

    }
</script>
<style>
</style>